import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import css from '@emotion/css'
import { keyframes } from '@emotion/core'
import posed from 'react-pose'
import ReactCountryFlag from 'react-country-flag'

import { rhythm } from '../utils/typography'
import HighlightIconLeft from '../assets/svgs/highlight-left.svg'
import HighlightIconRight from '../assets/svgs/highlight-right.svg'
import { Colors } from '../utils/colors'

const navbarItems = [
  {
    key: 'accueil',
    link: '/',
    labels: {
      fr: 'accueil',
      en: 'home',
    },
  },
  {
    key: 'la boutique',
    link: '/shop',
    labels: {
      fr: 'la boutique',
      en: 'shop',
    },
  },
  {
    key: 'news',
    link: '/news',
    highlight: true,
    labels: {
      fr: 'news',
      en: 'news',
    },
  },
  {
    key: 'blog',
    link: '/blog',
    labels: {
      fr: 'blog',
      en: 'blog',
    },
  },
  {
    key: 'contact',
    link: '/contact',
    labels: {
      fr: 'contact',
      en: 'contact',
    },
  },
]

const NavbarContainer = styled.nav`
  text-align: center;
  margin-bottom: ${rhythm(3)};
  background: linear-gradient(
      to right,
      rgba(246, 169, 207, 0.6) 0%,
      rgba(45, 246, 213, 0.6) 20%,
      rgba(246, 169, 207, 0.6) 60%,
      rgba(45, 246, 213, 0.6) 100%
    )
    left bottom transparent no-repeat;
  background-size: 100% 6px;
  padding-bottom: ${rhythm(1 / 2)};
  ol {
    list-style-type: none;
    padding-left: 0;
    margin-top: ${rhythm(1)};
    margin-bottom: ${rhythm(0.5)};
  }
`

const NavbarItemContainer = posed(styled.li`
  display: inline-block;
  margin-right: ${rhythm(1)};
  font-size: ${rhythm(1.2)};
  line-height: ${rhythm(0.5)};
  padding: 4px;

  a {
    box-shadow: none;
    text-decoration: none;
    color: inherit;
    font-family: 'Raleway', sans-serif;
  }
`)({
  hoverable: true,
  init: {
    backgroundColor: props =>
      props.isActive ? Colors.green : 'rgba(255, 255, 255, 0)',
  },
  hover: {
    backgroundColor: Colors.green,
  },
})

const NavbarLink = styled(Link)`
  display: inline-flex;
  align-items: center;
`

const tada = keyframes`
  0% {
    transform: scale(1)
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-3deg)
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg)
  }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg)
  }
  100% {
    transform: scale(1) rotate(0)
  }
`

const HighlightLeft = styled(HighlightIconLeft)`
  margin-right: 5px;
  animation: ${tada} 1s infinite ease-in-out;
`

const HighlightRight = styled(HighlightIconRight)`
  margin-left: 5px;
  animation: ${tada} 1s infinite ease-in-out;
`

const NavbarItem = ({ label, link, isActive, highlight }) => {
  return (
    <NavbarItemContainer isActive={isActive} highlight={highlight}>
      <NavbarLink to={link}>
        {highlight && <HighlightLeft />}
        {label}
        {highlight && <HighlightRight />}
      </NavbarLink>
    </NavbarItemContainer>
  )
}

const LanguagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const LangLink = styled(({ isCurrentLang, ...rest }) => <Link {...rest} />)`
  display: inline-block;
  margin-top: 0;
  margin-left: ${rhythm(0.2)};
  margin-right: ${rhythm(0.2)};
  margin-bottom: ${rhythm(0.5)};

  ${p =>
    p.isCurrentLang
      ? css`
          transform: scale(1.2);
          border-bottom: 2px solid hsl(166, 80.1%, 41%);
        `
      : ''}
`

function Languages({ langs, langKey, currentUrl }) {
  function changeLang(lang) {
    if (currentUrl.indexOf('/news/') > 0) {
      return `/${lang}/news/`
    } else if (currentUrl.indexOf('/blog/') > 0) {
      return `/${lang}/blog/`
    }

    return currentUrl.replace(`/${langKey}`, `/${lang}`)
  }
  return (
    <LanguagesContainer>
      {langs.map(lang => {
        const isCurrentLang = lang === langKey
        return (
          <LangLink
            key={lang}
            to={changeLang(lang)}
            isCurrentLang={isCurrentLang}
          >
            <ReactCountryFlag
              styleProps={{
                width: '25px',
                height: '25px',
              }}
              code={lang.replace('en', 'gb')}
              svg
            />
          </LangLink>
        )
      })}
    </LanguagesContainer>
  )
}

const isActive = (currentUrl, link) => {
  const baseUrlWithoutLang = `/${currentUrl.split('/')[2]}`
  return baseUrlWithoutLang === link
}

function Navbar({ site, langKey, currentUrl }) {
  const { langs } = site.siteMetadata.languages
  return (
    <NavbarContainer>
      <ol>
        {navbarItems.map(({ key, link, highlight, labels }) => (
          <NavbarItem
            key={key}
            label={labels[langKey]}
            link={`/${langKey}${link}`}
            isActive={isActive(currentUrl, link)}
            highlight={highlight}
          />
        ))}
      </ol>
      <Languages langs={langs} langKey={langKey} currentUrl={currentUrl} />
    </NavbarContainer>
  )
}

export default Navbar
