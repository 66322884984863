import React from 'react'
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'

import karalabelIcon from '../../static/assets/favicon.png'
import useLocation from '../utils/useLocation'

function SiteMetadata({ langKey, customKeywords, customDescription }) {
  const { location } = useLocation()

  const pathname = location ? location.pathname : ''
  return (
    <StaticQuery
      query={graphql`
        query SiteMetadata {
          site {
            siteMetadata {
              siteUrl
              title
              twitter
              description
              keywords
              pinterestDomainVerify
              facebookAppId
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: {
            siteUrl,
            title,
            twitter,
            description,
            keywords,
            pinterestDomainVerify,
            facebookAppId,
          },
        },
      }) => (
        <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
          <html lang={langKey ? langKey : 'fr'} />
          <link rel="canonical" href={`${siteUrl}${pathname}`} />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
          />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />

          <meta charSet="UTF-8" />
          <meta
            name="Description"
            content={customDescription ? customDescription : description}
          />
          <meta
            name="Keywords"
            content={customKeywords ? customKeywords : keywords}
          />
          <meta name="Identifier-Url" content={siteUrl} />
          <meta name="Revisit-After" content="2 days" />
          <meta name="Robots" content="all" />
          <meta name="Rating" content="general" />
          <meta name="Category" content="shopping" />

          <meta property="og:url" content={siteUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content={langKey} />
          <meta property="og:site_name" content={title} />
          <meta property="og:image" content={`${siteUrl}${karalabelIcon}`} />
          <meta property="og:image:width" content="512" />
          <meta property="og:image:height" content="512" />
          <meta property="og:title" content={title} />
          <meta
            property="og:description"
            content={customDescription ? customDescription : description}
          />

          <meta property="fb:app_id" content={facebookAppId} />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={twitter} />
          <meta name="twitter:title" content={title} />
          <meta
            name="twitter:description"
            content={customDescription ? customDescription : description}
          />
          <meta name="twitter:image" content={`${siteUrl}${karalabelIcon}`} />

          <meta name="p:domain_verify" content={pinterestDomainVerify} />
        </Helmet>
      )}
    />
  )
}

export default SiteMetadata
