import React from 'react'
import { graphql, Link, StaticQuery, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import posed from 'react-pose'
import { Sticky as StickyDiv } from 'react-sticky'
import { isMobile } from 'react-device-detect'
import Img from 'gatsby-image'

import LogoAnimated from '../assets/svgs/logo-animated-optim.svg'

import { rhythm } from '../utils/typography'
import { goToTop } from '../utils/navigation'
import Helmet from 'react-helmet'
import karalabelIcon from '../../static/assets/favicon.png'

export const LOGO_WIDTH = '200px'

const LogoSvg = styled.svg`
  width: ${LOGO_WIDTH};
  max-width: 100%;
  height: auto;
  padding: 20px;
  margin-right: ${rhythm(1)};

  ${p =>
    p.overflow
      ? css`
          overflow: ${p.overflow};
        `
      : ''};
`

const VinylDiamond = posed.g({
  hoverable: false,
  init: {
    transformOrigin: '50% 50%',
    transform: 'scale(1) rotate(0)',
  },
  hover: {
    transformOrigin: '50% 50%',
    transform: 'scale(0.7) rotate(-30)',
  },
})

const Banner = posed.g({
  hoverable: false,
  init: {
    transformOrigin: '50% 50%',
    transform: 'scale(1) translate(-30, 0)',
  },
  hover: {
    transformOrigin: '50% 50%',
    transform: 'scale(0.9) translate(-30, -40)',
    transition: {
      delay: 200,
    },
  },
})

const DivSticky = posed(styled.div`
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 999;
  cursor: pointer;
`)({
  init: {
    left: 0,
    opacity: 1,
    applyAtStart: {
      opacity: 0,
      left: -200,
      top: -200,
    },
  },
  sticky: {
    opacity: 1,
    display: 'block',
    left: 0,
    top: 0,
    height: 'auto',
    width: 'auto',
    transition: { type: 'spring', stiffness: 50 },
  },
  hidden: {
    position: 'fixed',
    left: 0,
    top: '-300px',
    opacity: 0,
    width: 0,
    height: 0,
  },
})

export const Logo2 = ({ pose, animateWithParent, overflow }) => (
  <LogoSvg viewBox="0 0 580 524" version="1.1" overflow={overflow}>
    <defs>
      <filter
        x="-0.3"
        y="-0.3"
        width="101"
        height="101.2"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset dx="1" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="0.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <polygon
        id="path-2"
        points="0.59 115.73 11.85 8.97 59.29 0.53 111.83 52.45 105.59 100.13"
      />
      <filter
        x="-1.2"
        y="-1.8"
        width="102.4"
        height="105"
        filterUnits="objectBoundingBox"
        id="filter-4"
      >
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Logo" transform="translate(0.000000, -31.000000)">
        <g id="Kara-label-final" transform="translate(0.000000, 31.000000)">
          <VinylDiamond
            pose={pose ? pose : 'init'}
            id="vinyl_diamond"
            name="vinyl_diamond"
            transform="scale(1) rotate(0)"
            withParent={animateWithParent}
          >
            <g id="vinyl" filter="url(#filter-1)">
              <g id="Layer_1">
                <circle id="Oval" fill="#242424" cx="256" cy="256" r="256" />
                <path
                  d="M256 505.6C118.37 505.6 6.4 393.63 6.4 256 6.4 118.37 118.37 6.4 256 6.4 393.63 6.4 505.6 118.37 505.6 256 505.6 393.63 393.63 505.6 256 505.6Z"
                  id="Shape"
                  fill="#0F161A"
                />
                <g
                  id="Group"
                  opacity="0.2"
                  transform="translate(6.000000, 79.000000)"
                  fill="#588199"
                >
                  <path
                    d="M73.59 0.58C28.4 45.79 0.4 108.18 0.4 177 0.4 245.82 28.4 308.21 73.59 353.42L250 177 73.59 0.58Z"
                    id="Shape"
                  />
                  <path
                    d="M499.6 177C499.6 108.19 471.61 45.79 426.41 0.58L250 177 426.42 353.42C471.61 308.21 499.6 245.81 499.6 177Z"
                    id="Shape"
                  />
                </g>
                <path
                  d="M256 352C203.06 352 160 308.94 160 256 160 203.06 203.06 160 256 160 308.94 160 352 203.06 352 256 352 308.94 308.94 352 256 352Z"
                  id="Shape"
                  fill="#66401E"
                />
                <path
                  d="M256 339.2C210.12 339.2 172.8 301.88 172.8 256 172.8 210.12 210.12 172.8 256 172.8 301.88 172.8 339.2 210.12 339.2 256 339.2 301.88 301.88 339.2 256 339.2Z"
                  id="Shape"
                  fill="#FFEACC"
                />
                <circle id="Oval" fill="#242424" cx="256" cy="256" r="6.4" />
              </g>
            </g>
            <g
              id="diamond"
              name="diamond"
              transform="translate(329.000000, 67.000000)"
            >
              <g
                id="diamond-ring"
                transform="translate(58.000000, 59.000000) rotate(1.000000) translate(-58.000000, -59.000000) translate(2.000000, 1.000000)"
              >
                <mask id="mask-3" fill="white">
                  <use href="#path-2" />
                </mask>
                <use id="Mask" fill="#FFFFFF" href="#path-2" />
                <g mask="url(#mask-3)">
                  <g transform="translate(-54.311377, -24.929412)">
                    <g transform="translate(96.751051, 96.663710) rotate(46.000000) translate(-96.751051, -96.663710) translate(27.751051, 29.663710)">
                      <g
                        id="Layer_1"
                        transform="translate(-0.000000, 0.000000)"
                      >
                        <g
                          id="diamond-(5)"
                          transform="translate(69.221375, 66.728791) rotate(-2.000000) translate(-69.221375, -66.728791) translate(2.721375, 2.728791)"
                        >
                          <g
                            id="Layer_1"
                            transform="translate(0.000000, 0.000000)"
                          >
                            <polygon
                              id="Shape"
                              fill="#FFFFFF"
                              points="11.77 27.82 66.46 87.36 121.15 27.82 97.05 0.32 35.86 0.32"
                            />
                            <polygon
                              id="Shape"
                              fillOpacity="0.65"
                              fill="#F6A9CF"
                              points="40.9 40.24 0 40.24 66.26 127.29"
                            />
                            <polygon
                              id="Shape"
                              fill="#F6A9CF"
                              points="132.52 40.24 91.62 40.24 66.26 127.29 66.26 127.29"
                            />
                            <g
                              id="Group"
                              transform="translate(-0.000000, 0.000000)"
                              fill="#F6A9CF"
                            >
                              <polygon
                                id="Shape"
                                fillOpacity="0.83"
                                points="91.62 40.24 40.9 40.24 66.26 127.29 66.26 127.29"
                              />
                              <polygon
                                id="Shape"
                                fillOpacity="0.74"
                                points="29.19 0.04 0 40.24 40.9 40.24"
                              />
                            </g>
                            <polygon
                              id="Shape"
                              fill="#F198C4"
                              points="91.62 40.24 132.52 40.24 103.33 0.04"
                            />
                            <polygon
                              id="Shape"
                              fillOpacity="0.74"
                              fill="#F6A9CF"
                              points="103.33 0.04 103.33 0.04 66.26 0.04 91.62 40.24"
                            />
                            <polygon
                              id="Shape"
                              fillOpacity="0.64"
                              fill="#F6A9CF"
                              points="40.9 40.24 66.26 0.04 29.19 0.04 29.19 0.04"
                            />
                            <polygon
                              id="Shape"
                              fill="#F6A9CF"
                              points="40.9 40.24 91.62 40.24 66.26 0.04"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <path
                d="M116.12 65.75C110.27 64.41 105.66 60.36 104.14 55.22 103.99 54.7 103.23 54.7 103.08 55.22 101.56 60.36 96.96 64.41 91.1 65.75 90.52 65.88 90.52 66.55 91.1 66.68 96.96 68.01 101.56 72.06 103.08 77.21 103.23 77.72 103.99 77.72 104.14 77.21 105.66 72.06 110.27 68.01 116.12 66.68 116.71 66.55 116.71 65.88 116.12 65.75Z"
                id="Shape"
                fill="#FFFFFF"
                transform="translate(103.611197, 66.213624) rotate(11.000000) translate(-103.611197, -66.213624) "
              />
              <path
                d="M88.25 74.32C85.23 73.56 82.85 71.25 82.07 68.31 81.99 68.02 81.59 68.02 81.51 68.31 80.73 71.25 78.35 73.56 75.33 74.32 75.03 74.4 75.03 74.78 75.33 74.85 78.35 75.62 80.73 77.93 81.51 80.86 81.59 81.16 81.99 81.16 82.07 80.86 82.85 77.93 85.23 75.62 88.25 74.85 88.55 74.78 88.55 74.4 88.25 74.32Z"
                id="Shape"
                fill="#FFFFFF"
                transform="translate(81.790084, 74.587656) rotate(7.000000) translate(-81.790084, -74.587656) "
              />
            </g>
          </VinylDiamond>
          <Banner
            transform="scale(1) translate(-30, 0)"
            pose={pose ? pose : 'init'}
            withParent={animateWithParent}
          >
            <g
              id="ribbon-black-shape"
              filter="url(#filter-4)"
              transform="translate(0.000000, 241.000000)"
            >
              <g id="Capa_1">
                <g id="Group">
                  <path
                    d="M579.38 277.97C579.38 277.97 579.01 274.93 576.45 270.08L579.38 277.97Z"
                    id="Shape"
                    fill="#FFDA44"
                  />
                  <path
                    d="M578.16 147.44C572.57 72.61 306.2 77.7 288.03 78.13 61.08 80.54 12.74 27.68 2.64 7.87L39.52 110.68 1.2 131.05C6.8 205.89 273.17 200.79 291.33 200.36 516.31 197.97 565.75 249.87 576.45 270.08L539.89 171.55 578.16 147.44Z"
                    id="Shape"
                    fillOpacity="0.83"
                    fill="#00FED9"
                  />
                  <path
                    d="M2.64 7.87L0 0.52C0 0.52 0.31 3.32 2.64 7.87Z"
                    id="Shape"
                    fill="#FFDA44"
                  />
                </g>
              </g>
            </g>
            <g id="name" transform="translate(0.000000, 321.000000)">
              <path
                d="M0.28 22.38C2.09 22.38 34.93 67.26 111.55 79.64 137.48 83.83 212.46 76.01 294.38 79.64 376.3 83.27 399.48 93.72 439.5 99.6 544.79 115.07 579 199.59 579 199.59"
                id="namepath"
              />
              <text
                id="Kara-Label"
                fontFamily="LobsterTwo-BoldItalic, Lobster Two"
                fontSize="93"
                fontStyle="italic"
                fontWeight="bold"
                fill="#000000"
              >
                <tspan x="87.24" y="93">
                  Kara Label
                </tspan>
              </text>
            </g>
          </Banner>
        </g>
      </g>
    </g>
  </LogoSvg>
)

export const StickyLogo = () => (
  <StickyDiv topOffset={600} disableCompensation>
    {({ isSticky, calculatedHeight }) => {
      const pose = isSticky && !isMobile ? ['sticky', 'hover'] : 'hidden'
      return (
        <div style={{ height: calculatedHeight }}>
          <DivSticky pose={pose} onClick={() => goToTop()}>
            <Logo pose={pose} animateWithParent />
          </DivSticky>
        </div>
      )
    }}
  </StickyDiv>
)

const LogoAnimatedStyled = styled(LogoAnimated)`
  overflow: visible !important;
`

function Logo() {
  return (
    <StaticQuery
      query={graphql`
        query LogoQuery {
          logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fixed(width: 180) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={({ logo }) => (
        <Img
          fixed={logo.childImageSharp.fixed}
          style={{ marginRight: rhythm(1) }}
        />
      )}
    />
  )
}

export const HeaderLogo = () => {
  return (
    <Link
      style={{
        boxShadow: 'none',
        textDecoration: 'none',
        color: 'inherit',
      }}
      to={'/'}
    >
      <Logo animateWithParent overflow="visible!important" />
    </Link>
  )
}
