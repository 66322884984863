import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import css from '@emotion/css'
import { StickyContainer } from 'react-sticky'
import Helmet from 'react-helmet'
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants'
import { getCurrentLangKey } from 'ptz-i18n'

import Header from './header'
import { rhythm } from '../utils/typography'
import SiteMetadata from './site-metadata'

import useLocation from '../utils/useLocation'

const Layout = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(40)};
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};

  ${MOBILE_MEDIA_QUERY} {
    padding: ${rhythm(1 / 2)};
    max-width: none;
  }
`

function Template({
  children,
  title,
  subtitle,
  site,
  customKeywords,
  customDescription,
}) {
  const {
    location: { pathname },
  } = useLocation()
  const { langs, defaultLangKey } = site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, pathname)
  return (
    <Layout>
      <Helmet title={title} />
      <Global
        styles={css`
          .youtube-container {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;

            iframe,
            object,
            embed {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        `}
      />
      <SiteMetadata
        langKey={langKey}
        customKeywords={customKeywords}
        customDescription={customDescription}
      />
      <StickyContainer>
        <Header
          subtitle={subtitle}
          site={site}
          currentUrl={pathname}
          langKey={langKey}
        />
        {children}
      </StickyContainer>
    </Layout>
  )
}

export default Template

export const query = graphql`
  fragment SiteInformation on Site {
    siteMetadata {
      title
      siteUrl
      description
      languages {
        defaultLangKey
        langs
      }
    }
  }
`
